<template>
  <section>
    <div class="pageBradcrumbs">
      <div class="containerLarge">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb small">
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/marine', 'marine')">Home</b-button>
            </li>
            <li class="breadcrumb-item">
              <b-button @click="toGlobal('/products', 'marine')">Products</b-button>
            </li>
          </ol>
        </nav>
      </div>
    </div>
    <div class="banner">
      <div
        class="bannerImage"
        :style="{
          'background-image':
            'url(' + getCover(element.cover) + ')',  
        }" 
      >
        <div class="containerLarge">
          <div class="pageTitle-banner text-center">
            <div class="pageTitle-content">
              <h1 class="mt-4">{{element.shortname}} • {{element.name}}</h1>
              <div class="divider"></div>
              <p class="lead">
                {{element.subtitle}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="caterory mt-5 pt-lg-3">
      <div class="containerLarge">
          <div class="row">
              <div class="col-md-10 offset-md-1 col-lg-6 offset-lg-3">
                  <div class="lead" v-html="element.abstract">
                  </div>
              </div>
          </div>
      </div>
      <div class="accordion mt-4 mt-lg-5" role="tablist">
        <!-- accordion -->
        <b-card v-if="element.models.length" no-body class="cardAccordion my-1">
          <b-card-header header-tag="header" class="card-header" role="tab">
            <div class="container px-0">
                <h2 class="h4 mb-0">
                    <a v-b-toggle.accordion-1 class="text-decoration-none d-flex flex-row justify-content-between">
                       <span>Models </span>
                       <b-icon-chevron-right></b-icon-chevron-right>
                    </a>
                </h2>
            </div>
          </b-card-header>
          <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="container"> 
                <div class="row mt-3">

                  <div v-for="el in element.models" :key="el._id" 
                  class="col-12 col-md-6 col-lg-4 col-lg-3 mb-3 d-flex align-items-stretch"
                  @click="toModel(el._id, element.name)">
                    <div class="cardBox">
                      <div class="cardModel">                       
                          <div class="row">
                            <div class="col-4 col-md-12 text-center">            
                              <img class="img-fluid" :src="getImage(el.imgproducts)">
                            </div>
                            <div class="col-8 col-md-12">
                              <div class="card-body">
                                <h3 class="card-title">{{el.name}}</h3>
                                <p>{{el.abstract.substr(0, 190)}}...</p>
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- accordion -->
        <b-card v-if="element.moreinfo_column1 || element.moreinfo_column2" no-body class="cardAccordion my-1">
          <b-card-header header-tag="header" class="card-header" role="tab">
            <div class="container px-0">
                <h2 class="h4 mb-0">
                    <a v-b-toggle.accordion-2 class="text-decoration-none d-flex flex-row justify-content-between">
                       <span>More Information</span>
                       <b-icon-chevron-right></b-icon-chevron-right>
                    </a>
                </h2>
            </div>
          </b-card-header>
          <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="container"> 
                <div class="row mt-3">
                  <div class="col-md-6" v-html="element.moreinfo_column1"></div>
                  <div class="col-md-6" v-html="element.moreinfo_column2"></div>
                </div>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
        <!-- accordion -->
        <b-card v-if="element.documents? element.documents.length > 0 : false" no-body class="cardAccordion my-1">
          <b-card-header header-tag="header" class="card-header" role="tab">
            <div class="container px-0">
                <h2 class="h4 mb-0">
                    <a v-b-toggle.accordion-3 class="text-decoration-none d-flex flex-row justify-content-between">
                       <span>Documents</span>
                       <b-icon-chevron-right></b-icon-chevron-right>
                    </a>
                </h2>
            </div>
          </b-card-header>
          <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <div class="container"> 
                <ul class="listDocuments list-unstyled">

                  <li v-for="doc in orderedDocs(element.documents)" :key="doc.name">
                      <a v-if="!doc.archive" class="py-3" @click="show(doc)">
                          <div class="float-left pr-3">
                              <b-icon-file-earmark-text-fill font-scale="2"></b-icon-file-earmark-text-fill>
                          </div>
                          <div class="listDocuments-info">
                              {{doc.name}}
                          </div>
                      </a>
                  </li>
                </ul>
 
                <b-modal v-model="showModal" id="modal-1" title="Documents Downloads" hide-footer>
                  <form-download :name="name" :file="file" :link="link" :type="type" :parent="element.name" :categoryId="getCategoryId" :elementId="getCategoryId" @close="close"></form-download>
                </b-modal>
              </div>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <div class="sticky-bottom mt-5 mb-5 text-center">
        <b-button class="btn btn-lg btn-turquoise py-2 px-5" @click="toSys(element.slug, element.name)">Size your system</b-button>
    </div>
  </section>
</template>

<script>
import FormDownload from '../components/FormDownload.vue';

export default {
  name: 'Category',

  components: {
    FormDownload
  },

  data: () => ({
    type: 'categoria',
    name: '',
    file: '',
    link: false,
    showModal: false,
    element: {}
  }),
  
  mounted(){
    this.getCategory();
  },

  computed: {
    getCategoryId(){
      return this.$route.params.id;
    },

    getCurrentPath(){
      return this.$store.getters.getCurrentPath;
    }
    
  },

  methods: {   

    orderedDocs(list){
      return list.slice().sort(this.compare);
    },
  
    compare(a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    },

    close(){
      this.showModal = false;
    },

    show(doc){
      this.name = doc.name;
      this.file = doc.file;
      this.link = doc.link ? doc.link : false;
      this.showModal = true;
    },

    getCover(img) {
      /* eslint-disable global-require */
      const tmp = require('../assets/img/banner/slide-01.jpg');
      return img ? process.env.VUE_APP_CLOUDINARY_BASE + img : tmp;
    },

    getImage(imgproducts) {
      const bucket = process.env.VUE_APP_CLOUDINARY_BUCKET;
      return imgproducts
        ? process.env.VUE_APP_CLOUDINARY_BASE + imgproducts            
        : process.env.VUE_APP_CLOUDINARY_BASE + bucket + '/placeholder.png';
    },

    toSys(slug, _name){
      //this.$router.push('/size-your-system/' + slug);
      this.$store.commit('setCurrentGlobal', 'marine');
      const withSlug = '/size-your-system/' + slug;
      this.$store.commit('setCurrentPath', withSlug);
      this.$store.commit('setCategoryId', this.$route.params.id);
      this.$store.commit('setCategoryName', _name);

      this.$router.push({
        name: 'Sys' + slug,
        params: {
          id: this.$route.params.id, //id categoria
          name: _name 
        }
      });
    },

    toGlobal(str, current){
      if (str !== this.getCurrentPath){
        this.$store.commit('setCurrentGlobal', current);
        this.$store.commit('setCurrentPath', str);
        this.$router.push(str);
      }      
    },

    toModel(_id, name){
      this.$store.commit('setCurrentGlobal', 'marine');
      const withModel = '/model/' + _id;
      this.$store.commit('setCurrentPath', withModel);
      this.$store.commit('setCategoryId', this.$route.params.id);
      this.$store.commit('setCategoryName', name);
      this.$router.push({
        name: 'Model',
        params: {
          id: _id,
          //  category_id: this.$route.params.id,
          //  category_name: name,
        }
      });
    },
    getCategory(){
      this.$http.get('/api/v1/categoria/get-by-id/?', { params: { id: this.$route.params.id } })
        .then((result) => {
          this.element = result.data;
        })
        .catch((err) => console.error('Problem on db: getCategory', err));
    }
  }
};
</script>

<style>
  .modal-title {
      font-weight: 700;
  }
</style>
